import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container, Grid, Box, Button } from '@mui/material';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from './components/Navbar';

function AllVendors() {
    const avatarStyle = {
        width: 30,
        height: 30,
        borderRadius: '50%',
        objectFit: 'cover',
        border: '2px solid #404040'
    };

    const business_label = {
        fontSize: 10,
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const query = searchParams.get('query')?.toLowerCase() || '';

    const [business, setBusiness] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();



    useEffect(() => {
        // Fetch user data by ID and set the initial state values
        axios.get(`https://martultimate.com/business`)
            .then(res => {
                setBusiness(res.data);

            })
            .catch(err => console.log(err));
    }, []);

   


    const handleGoBack = (event) => {
        event.preventDefault();
        navigate(-1);
    };

  


    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
            <Container style={{ marginTop: '4rem', color: '#fff' }}>

            <Grid container spacing={2} sx={{ padding: 2 }}>
  {business?.map((b) => (
    <Grid item xs={12} md={3} key={b.id}>
      <Card sx={{
        textAlign: 'center',
        color: '#fff',
        backgroundColor: '#303030',
        '&:hover': {
          transform: 'scale(1.02)',
          transition: 'transform 0.3s ease-in-out'
        }
      }} onClick={() => window.location.href = `../vendor/${b.id}`}>
        {/* Business Logo */}
        <CardMedia
          component="img"
          image={`https://martultimate.com/uploads/${b.logo}`}
          alt={`${b.name} logo`}
          sx={{
            height: 150,
            objectFit: 'contain',
            padding: 2,
            borderBottom: '2px solid #404040'
          }}
        />

        <CardContent>
          {/* Business Name */}
          <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
            {b.name}
          </Typography>

          {/* Business Slogan */}
          <Typography variant="subtitle2" sx={{ 
            color: '#cccccc',
            fontStyle: 'italic',
            mb: 2
          }}>
            "{b.slogan}"
          </Typography>

          {/* Contact Information */}
          <Box sx={{ 
            textAlign: 'left',
            padding: 2,
            backgroundColor: '#252525',
            borderRadius: 1,
            mb: 2
          }}>
            <Typography variant="body2" sx={{ color: '#00cc00' }}>
              📞 {b.phone}
            </Typography>
            <Typography variant="body2" sx={{ color: '#00cc00', mt: 1 }}>
              ✉️ {b.email}
            </Typography>
          </Box>

          {/* Website Link */}
          <Button 
            variant="contained" 
            href={b.web_url} 
            target="_blank"
            sx={{
              backgroundColor: '#00cc00',
              '&:hover': {
                backgroundColor: '#009900'
              }
            }}
          >
            Visit Website
          </Button>
        </CardContent>

        {/* Footer with Business ID */}
       
      </Card>
    </Grid>
  ))}
</Grid>

            </Container>

        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },

};
export default AllVendors;
