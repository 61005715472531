import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';

import Home from './Home';
import CreateVendor from './CreateVendor';
import CreateAccount from './CreateAccount';
import CreateBusiness from './CreateBusiness';
import CreateEmail from './CreateEmail';
import VerifyEmail from './VerifyEmail';
import Vendor from './Vendor';
import Checkout from './Checkout';

import SearchResult from './SearchResult';
import AllVendors from './AllVendors';

function App() {
  return (
    <div className="App">
    <div className="app-container">
    
    <BrowserRouter>
    <div>
    </div>
      <div className='main-content'>
      <Routes>
      <Route path='/' element={<Home />}></Route>
      <Route path='/create_vendor' element={<CreateVendor />}></Route>
      <Route path='/create_account' element={<CreateAccount />}></Route>
      <Route path='/create_business' element={<CreateBusiness />}></Route>
      <Route path='/create_email' element={<CreateEmail />}></Route>
      <Route path='/verify_email' element={<VerifyEmail />}></Route>
      <Route path='/vendor/:id' element={<Vendor />}></Route>

      <Route path='/search' element={<SearchResult />}></Route>
      <Route path='/checkout/:id' element={<Checkout />}></Route>

      <Route path='/all_vendors' element={<AllVendors />}></Route>
      
    
      </Routes>
      </div>
    </BrowserRouter>
    
    </div>
    </div>
  );
}

export default App;
