import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Grid, Paper } from '@mui/material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Card, CardMedia, CardContent } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import StorefrontIcon from '@mui/icons-material/Storefront'; // Markets around me
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; // Order now
import CategoryIcon from '@mui/icons-material/Category'; // Categories
import WhatshotIcon from '@mui/icons-material/Whatshot'; // Trending now
import LocalOfferIcon from '@mui/icons-material/LocalOffer'; // Deals and offers
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Enable location
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StarRateIcon from '@mui/icons-material/StarRate';
import ChatIcon from '@mui/icons-material/Chat';
import Switch from '@mui/material/Switch';

import FloatingTextCard from './components/FloatingTextCard';
import QRCode from './components/QrCodeComponent';

import { TextField, IconButton, InputAdornment, Fab } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from './components/Navbar';
import QrCodeComponent from './components/QrCodeComponent';



const label = { inputProps: { 'aria-label': 'Switch demo' } };

const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();


function Vendor() {

    const [transcript, setTranscript] = useState('');
    const [isListening, setIsListening] = useState(false);
    const [business, setBusiness] = useState('');
    const [product, setProduct] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        // Fetch user data by ID and set the initial state values
        axios.get(`https://martultimate.com/getBusiness/${id}`)
            .then(res => {
                setBusiness(res.data[0]);

            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        // Fetch user data by ID and set the initial state values
        axios.get(`https://martultimate.com/getVendorProduct/${id}`)
            .then(res => {
                setProduct(res.data);
            })
            .catch(err => console.log(err));
    }, []);

    const images = [
        "./shoe.jpg",
        "./deal.jpeg"
    ];

    const handleMicClick = () => {
        if (isListening) {
            recognition.stop();
            setIsListening(false);
        } else {
            recognition.start();
            setIsListening(true);
        }
    };

    // Speech recognition event handlers
    recognition.onstart = () => {
        setTranscript('');
    };

    recognition.onresult = (event) => {
        const current = event.resultIndex;
        const transcriptResult = event.results[current][0].transcript;
        setTranscript(transcriptResult);
        //console.log("Voice Input:", transcriptResult);
    };

    recognition.onend = () => {
        console.log("Voice recognition ended.");
        setIsListening(false); // Automatically stop listening after input
    };

    const [isChatOpen, setChatOpen] = useState(false);

    const toggleChat = () => {
        setChatOpen(!isChatOpen);
    };

    //camera
    const fileInputRef = useRef(null);

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                // Do something with the image, e.g., display it or upload
                console.log(reader.result); // This will be the image data URL
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };

    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
           
            <Container style={{ marginTop: '4rem', color: '#010101' }}>
                <Grid container spacing={2}>


                    {/* 8-column section */}
                    <Grid item xs={12} md={8}>
                        <Paper style={{ padding: 16, textAlign: 'left', backgroundColor: '#202020', color: 'white' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center', // Vertically align items
                                    gap: 1, // Add spacing between elements
                                }}
                            >
                                <Card sx={{ maxWidth: 50 }}>
                                    <CardMedia
                                        component="img"
                                        height="50"
                                        image={`https://martultimate.com/uploads/${business.logo}`} // Corrected syntax
                                        alt="Sample Image"
                                    />

                                </Card>
                                <Typography
                                    sx={{
                                        fontSize: '1.5rem', // Large font size
                                        background: 'linear-gradient(45deg, #008800 30%, #ffffff 90%)', // Gradient green to white
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}
                                >
                                    {business.name}
                                </Typography>
                            </Box>



                            <Card sx={{ m: 2 }}>
                                <CardMedia
                                    component="img"
                                    height="300"

                                    image={`https://martultimate.com/uploads/${business.logo}`} // Replace with your image URL
                                    alt="Sample Image"
                                />
                            </Card>

                            <Typography
                                sx={{
                                    fontSize: '1rem', // Large font size
                                    background: 'linear-gradient(160deg, #ffffff 50%,  #008800 50%)', // Gradient green to white
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                Certifications
                            </Typography>

                            <Grid container spacing={2} sx={{ padding: 2 }}>
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ textAlign: 'center', color: '#fff', backgroundColor: '#303030' }}>
                                        <CardMedia
                                            component="img"
                                            image={`https://martultimate.com/uploads/${business.logo}`} // Replace with your image URL
                                            alt="Market Trends"
                                            sx={{ height: 150, objectFit: 'cover' }} // Adjust height and cropping style
                                        />
                                    </Card>

                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Card sx={{ textAlign: 'center', color: '#fff', backgroundColor: '#303030' }}>
                                        <CardMedia
                                            component="img"
                                            image={`https://martultimate.com/uploads/${business.logo}`} // Replace with your image URL
                                            alt="Market Trends"
                                            sx={{ height: 150, objectFit: 'cover' }} // Adjust height and cropping style
                                        />
                                    </Card>

                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Card sx={{ textAlign: 'center', color: '#fff', backgroundColor: '#303030' }}>
                                        <CardMedia
                                            component="img"
                                            image={`https://martultimate.com/uploads/${business.logo}`} // Replace with your image URL
                                            alt="Market Trends"
                                            sx={{ height: 150, objectFit: 'cover' }} // Adjust height and cropping style
                                        />
                                    </Card>

                                </Grid>
                            </Grid>


                            <Typography
                                sx={{
                                    fontSize: '1rem', // Large font size
                                    background: 'linear-gradient(160deg, #ffffff 50%,  #008800 50%)', // Gradient green to white
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                Special Offers
                            </Typography>

                            <Grid container spacing={2} sx={{ padding: 2 }} >
                            
                                {product?.filter(p => p.special != 'None').map((p, index) => (
                                    <Grid item xs={12} md={4} key={p.id || index}>
                                        <Card sx={{
                                            textAlign: 'center',
                                            color: '#fff',
                                            backgroundColor: '#303030',
                                            '&:hover': {
                                                transform: 'scale(1.02)',
                                                transition: 'transform 0.3s ease-in-out'
                                            }
                                        }}
                                        onClick={() => window.location.href = `../checkout/${p.id}`}
                                        >
                                            <CardMedia
                                                component="img"
                                                image={`https://martultimate.com/uploads/${p.image}`}
                                                alt={p.name || "Product image"}
                                                sx={{
                                                    height: 150,
                                                    objectFit: 'cover',
                                                    borderBottom: '2px solid #404040'
                                                }}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" sx={{ mb: 1 }}>
                                                    <a href={`https://www.martultimate.com/vendor/${p.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        {p.product}
                                                    </a>
                                                </Typography>

                                                <Typography variant="body2" sx={{ color: '#00cc00' }}>
                                                    ₦{parseFloat(p.price).toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#cccccc' }}>
                                                    {p.special}
                                                </Typography>




                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>



                            <Typography
                                sx={{
                                    fontSize: '1rem', // Large font size
                                    background: 'linear-gradient(160deg, #ffffff 50%,  #008800 50%)', // Gradient green to white
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                Products
                            </Typography>

                            <Grid container spacing={2} sx={{ padding: 2 }}>
                                {product?.map((p, index) => (
                                    <Grid item xs={12} md={4} key={p.id || index}>
                                        <Card sx={{
                                            textAlign: 'center',
                                            color: '#fff',
                                            backgroundColor: '#303030',
                                            '&:hover': {
                                                transform: 'scale(1.02)',
                                                transition: 'transform 0.3s ease-in-out'
                                            }
                                        }} onClick={() => window.location.href = `../checkout/${p.id}`}>
                                            <CardMedia
                                                component="img"
                                                image={`https://martultimate.com/uploads/${p.image}`}
                                                alt={p.name || "Product image"}
                                                sx={{
                                                    height: 150,
                                                    objectFit: 'cover',
                                                    borderBottom: '2px solid #404040'
                                                }}
                                            />
                                            <CardContent>
                                                <Typography variant="h6" sx={{ mb: 1 }}>
                                                    <a href={`https://www.martultimate.com/vendor/${p.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        {p.product}
                                                    </a>
                                                </Typography>

                                                <Typography variant="body2" sx={{ color: '#00cc00' }}>
                                                    ₦{parseFloat(p.price).toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </Typography>
                                                <Typography variant="body2" sx={{ color: '#cccccc' }}>
                                                    {p.subcategory}
                                                </Typography>




                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>


                        </Paper>
                    </Grid>
                    {/* 4-column section */}
                    <Grid item xs={12} md={3}>
                        <Paper style={{ padding: 16, textAlign: 'center', backgroundColor: '#202020', color: 'white' }}>
                            <Typography variant="h6">
                                <Box component="span" sx={{ mr: 2 }}>
                                    My Profile
                                </Box>

                            </Typography>
                            <List>

                                <ListItem button>
                                    <ListItemIcon>
                                        <StorefrontIcon style={{ color: 'white' }} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2" style={{ color: 'white' }}>
                                                Product Upload
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <ShoppingCartIcon style={{ color: 'white' }} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2" style={{ color: 'white' }}>
                                                Orders
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <CategoryIcon style={{ color: 'white' }} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2" style={{ color: 'white' }}>
                                                Categories
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <WhatshotIcon style={{ color: 'white' }} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2" style={{ color: 'white' }}>
                                                Customers and Leads
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <LocalOfferIcon style={{ color: 'white' }} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2" style={{ color: 'white' }}>
                                                Deals and Offers
                                            </Typography>
                                        }
                                    />
                                </ListItem>

                                <ListItem button>
                                    <ListItemIcon>
                                        <LocationOnIcon style={{ color: 'white' }} fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="body2" style={{ color: 'white' }}>
                                                Enable Location
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </List>
                            <QrCodeComponent
                                value={`https://app.martultimate.com/vendor/${id}`}
                                size={160}
                                bgColor="#f0f0f0"
                                fgColor="#005000"
                                includeMargin={true}
                            />
                            <Typography variant="h6">
                                My Deals
                            </Typography>

                            <Carousel
                                autoPlay
                                interval={3000} // Change to your desired interval in milliseconds
                                infiniteLoop
                                showArrows={false} // Optional: Hide arrows
                                showThumbs={false} // Optional: Hide thumbnails
                                showIndicators={false} // Hide indicators
                                showStatus={false} // Hide current slide indicator
                            >
                                {product?.filter(p => p.special != 'None').map((p, index) => (
                                    <Card key={index} sx={{ maxWidth: 245 }}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={`https://martultimate.com/uploads/${p.image}`}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </Card>
                                )) }
                            </Carousel>

                            <Typography sx={{ m: 2 }}>Near Us</Typography>
                            <Card sx={{ textAlign: 'center', color: '#fff', backgroundColor: 'white' }}>
                                <CardContent>

                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.668738406345!2d-122.0842496846817!3d37.42199977982551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb6e63a403ef1%3A0x7dcb7288b5985b3!2sGoogleplex!5e0!3m2!1sen!2sus!4v1638224535986!5m2!1sen!2sus"
                                        width="100%"
                                        height="140"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Fab
                color="success"
                aria-label="chatbot"
                style={{ position: 'fixed', bottom: '20px', right: '20px' }}
                onClick={toggleChat}
            >
                <ChatIcon />
            </Fab>
            {isChatOpen && (
                <div style={{
                    position: 'fixed',
                    bottom: '80px',
                    right: '20px',
                    background: '#ffaa00',
                    padding: '15px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                    width: '300px',
                    zIndex: 1000
                }}>
                    <p style={{
                        margin: '0 0 10px',
                        fontWeight: 'light',
                        fontSize: '12px',
                        color: '#fff'
                    }}>
                        Hi, I am Ultimate Explorer. What can I do for you?
                    </p>
                    <input
                        type="text"
                        placeholder="Type your message..."
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0,0,0,0.1)',
                            fontSize: '14px'
                        }}
                    />
                </div>
            )}
        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },

};
export default Vendor;
