import React from 'react';
import PropTypes from 'prop-types';
import { QRCodeCanvas } from 'qrcode.react'; // Use named import

const QrCodeComponent = ({
  value,
  size = 128,
  bgColor = '#ffffff',
  fgColor = '#000000',
  includeMargin = false,
  imageSettings
}) => {
  return (
    <div style={{ padding: '16px' }}>
      <QRCodeCanvas
        value={value}
        size={size}
        bgColor={bgColor}
        fgColor={fgColor}
        includeMargin={includeMargin}
        level="H" // Error correction level (L, M, Q, H)
        imageSettings={imageSettings}
      />
      {value && (
        <div style={{ 
          marginTop: '16px',
          textAlign: 'center',
          fontSize: '0.8rem',
          color: '#666',
          wordBreak: 'break-all'
        }}>
          {value}
        </div>
      )}
    </div>
  );
};

QrCodeComponent.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
  bgColor: PropTypes.string,
  fgColor: PropTypes.string,
  includeMargin: PropTypes.bool,
  imageSettings: PropTypes.shape({
    src: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    excavate: PropTypes.bool
  })
};

export default QrCodeComponent;