import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Box, 
  Button, 
  Link, 
  Switch, 
  IconButton,
  Drawer,
  List,
  ListItem,
  Divider,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const styles = {
    appBar: {
      backgroundColor: 'rgba(40, 40, 40, 0.95)',
      backdropFilter: 'blur(10px)',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    logo: {
      height: '40px',
      [theme.breakpoints.down('sm')]: {
        height: '35px',
      },
    },
    navLinks: {
      display: 'flex',
      gap: '24px',
      alignItems: 'center',
    },
    mobileMenuButton: {
      display: { xs: 'block', md: 'none' },
      color: 'white',
    },
    desktopMenu: {
      display: { xs: 'none', md: 'flex' },
    },
    mobileMenu: {
      width: '250px',
      padding: '20px',
    },
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Convert menu items to an array for easier mapping
  const menuItems = [
    <Link key="home" href="/" color="inherit" underline="none" sx={{ p: 1 }}>Home</Link>,
    <Link key="about" href="https://profile.martultimate.com/" color="inherit" underline="none" sx={{ p: 1 }}>About</Link>,
    <Link key="privacy" href="https://profile.martultimate.com/" color="inherit" underline="none" sx={{ p: 1 }}>Privacy Policy</Link>,
    <Link key="terms" href="https://profile.martultimate.com/" color="inherit" underline="none" sx={{ p: 1 }}>Terms & Condition</Link>,
    <Link key="refund" href="https://profile.martultimate.com/" color="inherit" underline="none" sx={{ p: 1 }}>Refund Policy</Link>,
    <Link key="vendors" href="/all_vendors" color="inherit" underline="none" sx={{ p: 1 }}>Vendors</Link>,
  ];

  const authItems = (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Switch color="success" label="dark mode" />
      <Button 
        variant="contained" 
        color="success" 
        href='/create_email'
        sx={{ borderRadius: '8px' }}
      >
        Sign up
      </Button>
      <Button 
        variant="outlined" 
        color="success"
        sx={{ borderRadius: '8px' }}
      >
        Login
      </Button>
    </Box>
  );

  return (
    <AppBar position="fixed" sx={styles.appBar}>
      <Toolbar>
        {/* Mobile Menu Button */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={styles.mobileMenuButton}
        >
          <MenuIcon />
        </IconButton>

        {/* Logo */}
        <Box 
          component="img" 
          src="/logo.png" 
          alt="Logo" 
          sx={styles.logo} 
        />

        {/* Desktop Navigation */}
        <Box sx={{ flexGrow: 1, ml: 3, ...styles.desktopMenu }}>
          {menuItems}
        </Box>

        {/* Desktop Auth Items */}
        <Box sx={{ ...styles.desktopMenu }}>
          {authItems}
        </Box>

        {/* Mobile Drawer */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            '& .MuiDrawer-paper': styles.mobileMenu,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <List sx={{ pt: 2 }}>
            {/* Mobile Menu Items */}
            {menuItems.map((item, index) => (
              <ListItem key={index} sx={{ justifyContent: 'center' }}>
                {item}
              </ListItem>
            ))}
            
            {/* Mobile Auth Items - Stacked Vertically */}
            <ListItem sx={{ justifyContent: 'center' }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                gap: 2, 
                width: '100%',
                alignItems: 'center',
              }}>
                <Switch color="success" label="dark mode" />
                <Button 
                  fullWidth
                  variant="contained" 
                  color="success" 
                  href='/create_email'
                  sx={{ borderRadius: '8px' }}
                >
                  Sign up
                </Button>
                <Button 
                  fullWidth
                  variant="outlined" 
                  color="success"
                  sx={{ borderRadius: '8px' }}
                >
                  Login
                </Button>
              </Box>
            </ListItem>
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;