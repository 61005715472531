import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Box } from '@mui/material';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from './components/Navbar';





function SearchResult() {
    const avatarStyle = {
        width: 30,
        height: 30,
        borderRadius: '50%',
        objectFit: 'cover',
        border: '2px solid #404040'
    };

    const business_label = {
        fontSize: 10,
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const query = searchParams.get('query')?.toLowerCase() || '';


    const [business, setBusiness] = useState([]);
    const [product, setProduct] = useState([]);

    useEffect(() => {
        // Fetch user data by ID and set the initial state values
        axios.get(`https://martultimate.com/product_detail`)
            .then(res => {
                setProduct(res.data);

            })
            .catch(err => console.log(err));
    }, []);


    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
            <Container style={{ marginTop: '4rem', color: '#fff' }}>
                {query ? <p>Showing results for: <strong>{query}</strong></p> : <p>No search query provided.</p>}

                <Grid container spacing={2} sx={{ padding: 2 }}>
                    {product?.filter(p => p.name != query).map((p, index) => (
                        <Grid item xs={12} md={3} key={p.id || index}>
                            <Card sx={{
                                textAlign: 'center',
                                color: '#fff',
                                backgroundColor: '#303030',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    transition: 'transform 0.3s ease-in-out'
                                }
                            }}
                            onClick={() => window.location.href = `checkout/${p.id}`}
                            >
                                <CardMedia
                                    component="img"
                                    image={`https://martultimate.com/uploads/${p.image}`}
                                    alt={p.name || "Product image"}
                                    sx={{
                                        height: 150,
                                        objectFit: 'cover',
                                        borderBottom: '2px solid #404040'
                                    }}
                                />
                                <CardContent>
                                    <Typography variant="h6" sx={{ mb: 1 }}>
                                        <a href={`https://www.martultimate.com/vendor/${p.id}`}
                                            style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {p.product}
                                        </a>
                                    </Typography>

                                    <Typography variant="body2" sx={{ color: '#00cc00' }}>
                                        ₦{parseFloat(p.price).toLocaleString('en-NG', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                    </Typography>
                                </CardContent>

                                {/* Added Footer with Logo */}
                                <Box sx={{
                                    p: 1.5,
                                    borderTop: '1px solid #004000',
                                    backgroundColor: '#252525',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <img
                                        style={avatarStyle}
                                        src={`https://martultimate.com/uploads/${p.logo}`}
                                        alt="Vendor logo"
                                    />
                                    <span style={business_label}> {p.business}</span>
                                </Box>

                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Container>

        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },

};
export default SearchResult;
