import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container, Grid, Box, Button } from '@mui/material';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from './components/Navbar';
import { useFlutterwave } from 'flutterwave-react-v3';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';

function Checkout() {
    const avatarStyle = {
        width: 30,
        height: 30,
        borderRadius: '50%',
        objectFit: 'cover',
        border: '2px solid #404040'
    };

    const business_label = {
        fontSize: 10,
    }
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const query = searchParams.get('query')?.toLowerCase() || '';

    const [product, setProduct] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();



    useEffect(() => {
        // Fetch user data by ID and set the initial state values
        axios.get(`https://martultimate.com/specific_product_detail/${id}`)
            .then(res => {
                setProduct(res.data[0]);

            })
            .catch(err => console.log(err));
    }, []);

   

    const config = {
        public_key: 'FLWPUBK_TEST-7e758e10ab4cea495a5c224aa060dbd4-X',
        tx_ref: Date.now(),
        amount: product.price + product.price * 0.04,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: 'user@gmail.com',
            phone_number: '070********',
            name: 'john doe',
        },
        customizations: {
            title: 'my Payment Title',
            description: 'Payment for items in cart',
            logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
    };

    const handleFlutterPayment = useFlutterwave(config);

    const handleGoBack = (event) => {
        event.preventDefault();
        navigate(-1);
    };

    const formatPriceAsNaira = (price) => {
        return new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(price);
    };


    return (
        <div style={styles.background}>
            {/* Transparent Navbar with Logo and Nav Links */}
            <Navbar />
            {/* Main content */}
            <Container style={{ marginTop: '4rem', color: '#fff' }}>

                <Grid container spacing={2} sx={{ padding: 2 }}>

                    <Grid item xs={12} md={3} key={product.id}>
                        <Card sx={{
                            textAlign: 'center',
                            color: '#fff',
                            backgroundColor: '#303030',
                            '&:hover': {
                                transform: 'scale(1.02)',
                                transition: 'transform 0.3s ease-in-out'
                            }
                        }}>
                            <CardMedia
                                component="img"
                                image={`https://martultimate.com/uploads/${product.image}`}
                                alt={product.name || "Product image"}
                                sx={{
                                    height: 150,
                                    objectFit: 'cover',
                                    borderBottom: '2px solid #404040'
                                }}
                            />
                            <CardContent>
                                <Typography variant="h6" sx={{ mb: 1 }}>
                                    <a href={`https://www.martultimate.com/vendor/${product.id}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {product.product}
                                    </a>
                                </Typography>

                                <Typography variant="body2" sx={{ color: '#00cc00' }}>
                                    ₦{parseFloat(product.price).toLocaleString('en-NG', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </Typography>
                            </CardContent>

                            {/* Added Footer with Logo */}
                            <Box sx={{
                                p: 1.5,
                                borderTop: '1px solid #004000',
                                backgroundColor: '#252525',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <img
                                    style={avatarStyle}
                                    src={`https://martultimate.com/uploads/${product.logo}`}
                                    alt="Vendor logo"
                                />
                                <span style={business_label}> {product.business}</span>
                            </Box>

                        </Card>
                    </Grid>

                    <Grid item xs={12} md={3} key={product.id}>
                        <Button variant="outlined" color="success"
                            onClick={() => {
                                handleFlutterPayment({
                                    callback: (response) => {
                                        console.log(response);
                                        closePaymentModal() // this will close the modal programmatically
                                    },
                                    onClose: () => { },
                                });
                            }}
                        >Make Payment</Button>
                    </Grid>

                </Grid>

            </Container>

        </div>
    );
};

const styles = {
    background: {
        backgroundImage: `url(https://www.transparenttextures.com/patterns/asfalt-dark.png)`,
        backgroundColor: '#121212', // Fallback color for the dark theme
        minHeight: '100vh',
        backgroundSize: 'cover',
    },

};
export default Checkout;
